import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as Medium } from "../../assets/medium.svg";
import { ReactComponent as Telegram } from "../../assets/telegram.svg";
import { ReactComponent as Logout } from "../../assets/logout.svg";
import { ReactComponent as ExpandImage } from "../../assets/expand.svg";

export default {
  Twitter,
  Medium,
  Telegram,
  Logout,
  ExpandImage
};
