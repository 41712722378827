import { Component } from "react";
import PropTypes from "prop-types";
import { Fragment } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error, errorInfo) {
    return { error, errorInfo };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    const {
      props: { children },
      state: { error, errorInfo }
    } = this;

    if (error) {
      return (
        <div className="error-boundry">
          <h1>Something went wrong.</h1>
          <pre>
            {errorInfo && errorInfo.componentStack && (
              <Fragment>
                {error.toString()}
                {errorInfo.componentStack.toString()}
              </Fragment>
            )}
            {!errorInfo && error.stack && process.env.NODE_ENV !== "production"
              ? error.stack
              : error.toString()}
          </pre>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired
};

export default ErrorBoundary;
