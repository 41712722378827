import CustomSpinner from "assets/yield_loader.gif";
import classnames from "classnames";
import PropTypes from "prop-types";
import { memo } from "react";
import "./Spinner.scss";

function Spinner(props) {
  const spinnerProps = { ...props };
  const { overlay } = props;

  delete spinnerProps.overlay;

  return (
    <div className={classnames("spinner", { "spinner--overlay": overlay })}>
      <img src={CustomSpinner} alt="Loading" />
    </div>
  );
}

Spinner.propTypes = {
  overlay: PropTypes.bool
};

export default memo(Spinner);
