import members from "configs/members.json";
import NETWORKS from "configs/networks.json";
import { createContext, useEffect, useState } from "react";
export const MainContext = createContext({});

export const MainContextProvider = ({ children }) => {
  const [currentNetwork, setCurrentNetwork] = useState(1);
  const [address, setAddress] = useState("");
  const [isMember, setIsMember] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 768 && window.innerWidth <= 991);
  const [explorer, setExplorer] = useState(NETWORKS[currentNetwork].explorer);
  const supportedChains = [1, 43113, 80001];

  const checkIsMember = () => {
    if (address) {
      const isMember = members.includes(address.toLowerCase());
      setIsMember(isMember);
    } else {
      setIsMember(false);
    }
  };

  useEffect(() => {
    if (members.length > 0) checkIsMember();
  }, [address]);

  useEffect(() => {
    setExplorer(NETWORKS[currentNetwork].explorer);
  }, [currentNetwork]);

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 768);
    setIsTablet(window.innerWidth > 768 && window.innerWidth <= 991);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <MainContext.Provider
      value={{
        explorer,
        currentNetwork,
        setCurrentNetwork,
        address,
        setAddress,
        isMember,
        isMobile,
        isTablet,
        supportedChains
      }}>
      {children}
    </MainContext.Provider>
  );
};

export default MainContextProvider;
