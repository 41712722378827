import Spinner from "components/indicators/Spinner";
import { lazy, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import ErrorBoundary from "utils/ErrorBoundary";
import { BASIC_CONTRACT_TYPE } from "utils/poolHelper";
import Footer from "../components/footer";
import Header from "../components/header";
import { MainContextProvider } from "../context/context";
import "./App.css";

const Home = lazy(() => import("pages/home"));
const PoolPage = lazy(() => import("pages/pool"));
const PoolBasicPage = lazy(() => import("pages/pool-basic"));
const HowToPlay = lazy(() => import("pages/how-to-play"));
const AddPool = lazy(() => import("pages/add-pool"));

function App() {
  return (
    <>
      <Suspense fallback={<Spinner />}>
        <HashRouter>
          <div className="app">
            <MainContextProvider>
              <div className="app__body">
                <ErrorBoundary>
                  <Header className="app__header" />
                  <Routes>
                    <Route
                      path="/pool/basic/:poolIndex"
                      element={<PoolBasicPage contractType={BASIC_CONTRACT_TYPE.BASIC} />}
                    />
                    <Route
                      path="/pool/permissionless-basic/:poolIndex"
                      element={<PoolBasicPage contractType={BASIC_CONTRACT_TYPE.PERMISSIONLESS} />}
                    />
                    <Route path="/pool/resident/:poolIndex" element={<PoolPage />} />
                    <Route path="/how-to-play" element={<HowToPlay />} exact />
                    <Route path="/add-pool" element={<AddPool />} exact />
                    <Route path="/" element={<Home />} />
                  </Routes>
                </ErrorBoundary>
                <Footer />
              </div>
            </MainContextProvider>
          </div>
        </HashRouter>
      </Suspense>
    </>
  );
}

export default App;
