import { ChainDropdown, DisconnectWallet } from "evm-chain-scripts";
import { memo, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { chainDropdownStyles } from "utils/chainDropdownStyles";
import { formatAddress } from "utils/formats";
import "./WalletButtonMobile.scss";

const CustomSelectOption = ({ data: { setCurrentNetwork, supportedChains } }) => {
  return (
    <div className="wallet-dropdown__content">
      <div className="wallet-dropdown__content-label">Change network</div>
      <ChainDropdown
        onChange={setCurrentNetwork}
        className="dropdown"
        optionClassName="option"
        optionIconClassName="optionIcon"
        optionLabelClassName="optionLabel"
        shortName={true}
        supportedChains={supportedChains}
        styles={chainDropdownStyles}
      />
      <div className="wallet-dropdown__content-logout">
        <DisconnectWallet fill="#7DE213">Log Out</DisconnectWallet>
      </div>
    </div>
  );
};

const WalletButtonMobile = ({ address, setCurrentNetwork, supportedChains }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const wrapperRef = useRef(null);

  const useOutsideClose = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        const concernedElement = document.querySelector(".wallet-dropdown");

        if (!concernedElement?.contains(event.target)) {
          setIsSelectOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideClose(wrapperRef);

  return (
    <div ref={wrapperRef}>
      <Select
        menuIsOpen={isSelectOpen}
        onMenuOpen={() => setIsSelectOpen(true)}
        className="wallet-dropdown"
        options={[{ setCurrentNetwork, supportedChains }]}
        placeholder={formatAddress(address)}
        isSearchable={false}
        components={{
          Option: CustomSelectOption,
          IndicatorSeparator: () => null
        }}
      />
    </div>
  );
};

export default memo(WalletButtonMobile);
