import { getAddress } from "ethers/lib/utils";
import { WSEvents as Events } from "servment-base";

const project = process.env.REACT_APP_SUBSCRIBER_PROJECT ?? "YieldAppTest";
const uri = process.env.REACT_APP_SUBSCRIBER_URL || "subscriber-test.influencebackend.xyz";
const protocol = process.env.REACT_APP_SUBSCRIBER_PROTOCOL ?? "https";
let subscriber;

export function getEventSubscriber() {
  if (!subscriber) {
    subscriber = new Events(uri, protocol);
  }

  return subscriber;
}

export async function getAllEvents(chainId, contract, eventName, args, customQuery = null) {
  const abiNetwork = contract.networks[chainId];

  if (!abiNetwork) {
    console.error(`Cannot find abi network for ${project} : ${chainId}`);
    return null;
  }

  const { address: contractAddress, startBlock } = abiNetwork;
  const subscriber = getEventSubscriber();
  const abiEvents = subscriber.getEventsFromAbi(contract);

  let query = {};

  if (customQuery) {
    query = customQuery;
  } else {
    const contractStartBlock = !startBlock || startBlock < 0 ? 0 : startBlock;
    query = {
      fromBlock: contractStartBlock,
      args
    };
  }

  const requestBody = {
    abi: abiEvents,
    params: {
      contractAddress: getAddress(contractAddress),
      fromBlock: query.fromBlock,
      query
    }
  };

  try {
    const subResponse = await subscriber.getAllEvents({
      project,
      chainId,
      eventName,
      requestBody
    });

    if (subResponse.success) {
      return subResponse.allEvents || [];
    } else {
      console.error(
        `Error while trying to get all events for ${project} : ${chainId}`,
        subResponse.error
      );
    }
  } catch (err) {
    console.error(err);
  }

  return [];
}

export async function generateEventsSubscription(eventsName, chainId, contractAddress, callback) {
  const names = eventsName.map((name) => name);

  try {
    console.log(`Subscribing to ${names} events, chainId: ${chainId} contract: ${contractAddress}`);
    const subscriber = getEventSubscriber();
    subscriber.initializeEventListening(callback, chainId, contractAddress, eventsName);
  } catch (err) {
    console.error(`Error in generate ${names} subscription`, err);
  }
}
