import { MainContext } from "context/context";
import { ConnectWallet, ethInstance } from "evm-chain-scripts";
import { memo, useContext, useEffect } from "react";
import "./WalletButton.scss";

const WalletButton = () => {
  const { setAddress } = useContext(MainContext);

  useEffect(() => {
    window.ethereum?.on("accountsChanged", ethInstance.handleAccountsChanged.bind(ethInstance));
  }, []);

  const handleLoad = async () => {
    try {
      const ethAccount = await ethInstance.getEthAccount(false);
      setAddress(ethAccount);
    } catch (err) {
      setAddress(null);
    }
  };

  return (
    <ConnectWallet
      className="connect-wallet-btn"
      containerClassName="wallet-connect"
      headerClassName="wallet-connect__header"
      listButtonClassName="wallet-connect__list"
      addressContainerClassName="wallet-connect__address-container"
      onConnect={handleLoad}
    />
  );
};

export default memo(WalletButton);
