import Container from "react-bootstrap/Container";
import Icon from "../icons/index";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="footer__container">
          <div>
            Part of the&nbsp;
            <a href="https://finance.vote" target="_blank" rel="noreferrer">
              finance.vote
            </a>
            <span className="footer__version">v. {process.env.REACT_APP_VERSION}</span>
            &nbsp;dApp suite. 2021 All rights reserved
          </div>
          <div className="footer__links">
            <a href="https://t.me/financedotvote" target="_blank" rel="noreferrer">
              <Icon.Telegram fill="#7DE213" />
            </a>
            <a href="https://twitter.com/financedotvote" target="_blank" rel="noreferrer">
              <Icon.Twitter fill="#7DE213" />
            </a>
            <a href="https://medium.com/@financedotvote" target="_blank" rel="noreferrer">
              <Icon.Medium fill="#7DE213" />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
