import classnames from "classnames";
import YieldVoteLogo from "components/logos/YieldVoteLogo";
import { MainContext } from "context/context";
import { ChainDropdown, CustomSignIn } from "evm-chain-scripts";
import PropTypes from "prop-types";
import { memo, useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { chainDropdownStyles } from "utils/chainDropdownStyles";
import "./AppHeader.scss";
import WalletButton from "./wallet-button";
import WalletButtonMobile from "./wallet-button-mobile";

function AppHeader({ className }) {
  const { setCurrentNetwork, isMember, setAddress, address, isMobile, supportedChains } =
    useContext(MainContext);
  const [showDisclaimerDialog, setDisclaimerDialog] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const isConnected = address.length > 0;

  function handleCloseDisclaimerDialog() {
    setDisclaimerDialog(false);
    localStorage.setItem("disclaimerAccepted", true);
  }

  function handleAcceptTermsChange(data) {
    setTermsAccepted(data.target.checked);
  }

  useEffect(() => {
    if (!localStorage.getItem("disclaimerAccepted")) {
      setDisclaimerDialog(true);
    }
  }, []);

  return (
    <div className={classnames("app-header", className)}>
      <Container fluid className="app-header__container">
        <NavLink to="/">
          <YieldVoteLogo />
        </NavLink>

        <div className="buttons">
          {isConnected && (
            <WalletButtonMobile
              address={address}
              setCurrentNetwork={setCurrentNetwork}
              supportedChains={supportedChains}
            />
          )}

          <WalletButton />
          {!isMobile && (
            <ChainDropdown
              onChange={setCurrentNetwork}
              className="dropdown"
              shortName={true}
              optionClassName="option"
              optionIconClassName="optionIcon"
              optionLabelClassName="optionLabel"
              supportedChains={supportedChains}
              styles={chainDropdownStyles}
            />
          )}
          {!isMobile && isMember && (
            <CustomSignIn
              className="custom-sign-in"
              backdropClassName="custom-sign-in__backdrop"
              containerClassName="custom-sign-in__container"
              titleClassName="custom-sign-in__title"
              inputClassName="custom-sign-in__input"
              signInClassName="custom-sign-in__button"
              signOutClassName="custom-sign-in__button"
              onClose={(address) => setAddress(address)}
            />
          )}
        </div>
        <Modal className="disclaimer-form" show={showDisclaimerDialog} centered>
          <Modal.Body>
            <h2>Warning: This experiment is experimenty</h2>
            <div>
              yield.vote uses experimental cryptoeconomics to build decentralised money systems. Our
              contracts are audited, but there is no guarantee that they will not experience
              technical failure in the future. By using yield.vote, you recognise that you are
              taking a risk and that you are responsible for your own actions. Our monopoly themed
              yield games are competitive, you can be evicted by users that out-compete you on
              deposits and burn rate. In these games there is no guarantee that you will make money.
              We also have basic pools for our less competitive users.
            </div>
            <div style={{ marginTop: 50 }}>
              TL;DR: Basically, it works like this: you put tokens in and maybe you can take more
              tokens out later.
            </div>
            <Form.Check
              id="terms-accepted"
              type="checkbox"
              label="I have read the terms and conditions and understand how to use this product"
              onChange={handleAcceptTermsChange}
            />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              disabled={!termsAccepted}
              variant="primary"
              onClick={() => handleCloseDisclaimerDialog()}>
              I accept and want to continue
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

AppHeader.propTypes = {
  className: PropTypes.string
};

export default memo(AppHeader);
