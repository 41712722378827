import { ethInstance, fromWei } from "evm-chain-scripts";
import partnersJSON from "partners.json";
import { getBasicGlobalsAll } from "./basicPool";
import { getPermissionlessBasicGlobalsAll } from "./permissionlessBasicPool";
import { BASIC_CONTRACT_TYPE, contracts } from "./poolHelper";
import { getResidentGlobalsAll } from "./residentPool";

export async function getAllPartnersPools(light = false) {
  const [basicPools, permissionlessBasicsPools, residentPools] = await Promise.all([
    getBasicGlobalsAll(),
    getPermissionlessBasicGlobalsAll(),
    getResidentGlobalsAll(light)
  ]);

  const partners = {};
  const deposits = {};

  for (const partner of partnersJSON) {
    partners[partner.key] = {
      ...partner,
      basicPools: [],
      permissionlessBasicPools: [],
      residentPools: []
    };

    for (const token of partner.depositTokens) {
      deposits[token.toLowerCase()] = partner.key;
    }
  }

  for (const pool of basicPools) {
    const key = deposits[pool.depositToken.toLowerCase()];
    if (key && key !== "") partners[key].basicPools.push(pool);
  }

  for (const pool of permissionlessBasicsPools) {
    const key = deposits[pool.depositToken.toLowerCase()];
    if (key && key !== "") partners[key].permissionlessBasicPools.push(pool);
  }

  for (const pool of residentPools) {
    const key = deposits[pool.liquidityToken.toLowerCase()];
    if (key && key !== "") partners[key].residentPools.push(pool);
  }

  return Object.values(partners);
}

export async function getPartnersDeposit() {
  const result = { deposits: {}, partners: {} };

  for (const partner of partnersJSON) {
    result.partners[partner.key] = {
      ...partner,
      pools: []
    };

    for (const token of partner.depositTokens) {
      result.deposits[token.toLowerCase()] = partner.key;
    }
  }

  return result;
}

export function inputToArray(value) {
  return value ? value.split(",").map((item) => item.trim()) : null;
}

export const getPoolInstance = (type) => {
  switch (type) {
    case BASIC_CONTRACT_TYPE.BASIC:
      return {
        name: "basic",
        value: 0,
        contractType: BASIC_CONTRACT_TYPE.BASIC,
        contract: contracts.BasicPoolFactory,
        chainId: ethInstance.getChainId()
      };
    case BASIC_CONTRACT_TYPE.PERMISSIONLESS:
      return {
        name: "basic",
        value: 0,
        contractType: BASIC_CONTRACT_TYPE.PERMISSIONLESS,
        contract: contracts.PermissionlessBasicPoolFactory,
        chainId: ethInstance.getChainId()
      };
    case "resident":
      return {
        contract: contracts.Resident,
        chainId: ethInstance.getChainId()
      };
  }
};

export const getDepositToken = async (contractType, poolIndex, tokenAddress) => {
  if (contractType === "resident") {
    return await ethInstance.getReadContractByAddress(contracts.LPToken, tokenAddress);
  }
  const { contract } = getPoolInstance(contractType);
  const yieldContract = await ethInstance.getContract("read", contract);
  const pool = await yieldContract.pools(poolIndex);
  return await ethInstance.getWriteContractByAddress(contracts.Token, pool.depositToken);
};

export const getWriteContractByType = async (contractType) => {
  const { contract, chainId } = getPoolInstance(contractType);
  return await ethInstance.getWriteContractByAddress(contract, contract.networks[chainId].address);
};

export const getReceiptByType = async (contractType, receiptId, poolIndex) => {
  const { contract } = getPoolInstance(contractType);
  const yieldContract = await ethInstance.getContract("read", contract);
  return await yieldContract.getReceipt(poolIndex, receiptId);
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const getRewardsByType = async (contractType, receiptId, poolIndex) => {
  const { contract } = getPoolInstance(contractType);
  const yieldContract = await ethInstance.getContract("read", contract);
  let rewards = await yieldContract.getRewards(poolIndex, receiptId);
  rewards = rewards.map((reward) => fromWei(reward));
  return rewards;
};
