export const chainDropdownStyles = {
  option: (defaultStyles, { isSelected }) => {
    return {
      ...defaultStyles,
      backgroundColor: isSelected ? "#19673d" : "#103147",
      color: "#efefef",
      cursor: "pointer",
      paddingTop: "8px",
      paddingRight: "35px",
      paddingBottom: "8px",
      paddingLeft: "10px",

      ":hover": {
        backgroundColor: "#19673d",
        color: "#7de213"
      }
    };
  },
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#efefef" }),
  control: (defaultStyles) => ({
    ...defaultStyles,
    border: "solid 1px #19673d",
    backgroundColor: "#103147",
    width: "220px",
    borderRadius: 0,
    boxShadow: "none",
    color: "#efefef",
    ":hover": {
      border: "solid 1px #009245"
    }
  }),
  menuList: (defaultStyles) => ({
    ...defaultStyles,
    border: "solid 1px #19673d",
    padding: 0
  }),
  dropdownIndicator: (defaultStyles) => ({
    ...defaultStyles,
    svg: {
      fill: "#adb6bc",
      ":hover": {
        fill: "white"
      }
    }
  }),
  indicatorSeparator: () => ({
    display: "none"
  })
};
