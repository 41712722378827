import moment from "moment";
import numeral from "numeral";

export const countDecimals = (value) => {
  if (!value) return 0;

  const number = value.toString().split(".");

  if (number[1] && number[1]?.length) return number[1]?.length;
  else {
    return 0;
  }
};

export const formatTimeToDateShort = (time, separator = "-") =>
  moment(time * 1000).format(`DD${separator}MM${separator}YYYY`);

export const formatNumber = (number, precision = 2) => {
  if (number === "∞") {
    return "∞";
  }

  const decimalsCount = countDecimals(number);
  let _number = number;

  if (decimalsCount > precision) {
    const decimals = number.toString().split(".")[1].slice(0, precision);
    _number = [number.toString().split(".")[0], decimals + "0"].join(".");
  }

  const formattedNumber = numeral(_number).format("0,0.[".concat("0".repeat(precision), "]"));

  return formattedNumber;
};

export const formatAddress = (address) => {
  if (address) {
    const formattedAddress = address.slice(0, 6) + "..." + address.slice(-4);

    return formattedAddress;
  } else {
    return "";
  }
};
