import {
  bytesToString,
  ethInstance,
  fromWei,
  getInterface,
  stringToBytes,
  toBN,
  toWei
} from "evm-chain-scripts";
import specialPools from "specialPools.json";
import { ZERO_ADDRESS, getDepositToken, getPoolInstance, getWriteContractByType } from "./helper";
import {
  RESULT_TYPES,
  TRANSACTION_STATUS,
  accountConnected,
  contracts,
  getBytes,
  getChainSettings,
  getCurrentBlockNumber,
  getEventsFromCache,
  getTokenSymbol,
  parseBNData,
  secondsPerDay,
  secondsPerYear
} from "./poolHelper";

const PRECISION = toBN("1000000000");

export async function approveResidentSlot(deposit, tokenAddress) {
  const [liquidity, token, account] = await Promise.all([
    getWriteContractByType("resident"),
    ethInstance.getWriteContractByAddress(contracts.LPToken, tokenAddress),
    ethInstance.getEthAccount()
  ]);

  const currentApproval = parseFloat(fromWei(await token.allowance(account, liquidity.address)));
  if (currentApproval < parseFloat(deposit)) {
    const approvalAmount = process.env.REACT_APP_RESIDENT_POOL_APPROVAL_AMOUNT ?? "1000000";
    return await token.approve(liquidity.address, toWei(approvalAmount));
  }
}

export async function claimResidentSlot(poolIndex, slotId, burnRate, deposit) {
  const liquidity = await getWriteContractByType("resident");

  const pulses = await liquidity.pulses(poolIndex);
  const wavelength = pulses.pulseWavelengthBlocks;
  const burnPerBlock = toBN(toWei(burnRate.toString())).div(wavelength).toString();
  const depositWei = toWei(deposit.toString());

  return await liquidity.claimSlot(poolIndex, slotId, burnPerBlock, depositWei);
}

export async function updateResidentSlot(poolIndex, slotId) {
  const liquidity = await getWriteContractByType("resident");
  return await liquidity.updateSlot(poolIndex, slotId);
}

export async function withdrawResidentFromSlot(poolIndex, slotId) {
  const contract = await getWriteContractByType("resident");
  return await contract.withdrawFromSlot(poolIndex, slotId);
}

export async function getResidentDeposit(poolIndex, slotId) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const slot = await contract.getSlot(poolIndex, slotId);
  const deposit = fromWei(slot[1]);

  return deposit;
}

export async function getResidentRewards(poolIndex, slotId) {
  let result = { rewards1: 0, rewards2: 0 };

  try {
    const poolInstance = getPoolInstance("resident");

    const contract = await ethInstance.getContract("read", poolInstance.contract);
    const rewards = await contract.getRewards(poolIndex, slotId);
    result = { rewards1: fromWei(rewards[0]), rewards2: fromWei(rewards[1]) };

    return result;
  } catch (error) {
    console.error("getRewards: ", error);
    return result;
  }
}

export async function getMaxResidentStakers(poolIndex, chainId) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract, { chainId });
  const pool = await contract.pools(poolIndex);

  return pool.maxStakers;
}

export async function getMinResidentDeposit(poolIndex) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);

  return fromWei(await contract.minimumDeposit(poolIndex));
}

export async function getResidentOwner(poolIndex, slotId) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const slot = await contract.getSlot(poolIndex, slotId);

  return slot.owner;
}

export async function getResidentWaveLength(poolIndex, chainId) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract, { chainId });
  const pulses = await contract.pulses(poolIndex);
  const wavelength = toBN(pulses.pulseWavelengthBlocks);

  return wavelength;
}

export async function slotIsOccupied(poolIndex, slotId) {
  const owner = await getResidentOwner(poolIndex, slotId);

  return owner === ZERO_ADDRESS;
}

export async function getResidentBurnRate(poolIndex, slotId) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const slot = await contract.slots(poolIndex, slotId);

  return slot.burnRate;
}

export async function getResidentGlobals(poolIndex, currentNetwork) {
  try {
    const currentBlock = await (await ethInstance.getBlock("latest", currentNetwork)).number;

    const multi = await getResidentGlobalsMulti(poolIndex);
    const pulse = multi[0];
    const pool = multi[1];
    const poolStats = multi[2];
    const poolMetadata = multi[3];
    const waveLength = pulse.pulseWavelengthBlocks;

    const currentReward = calcResidentRewardForBlock(pulse, toBN(currentBlock));
    const rewardsPerPulse = calcResidentRewardPerPulse(pulse);

    const minBurnRatePerPulse = calcResidentBurnPerPulse(
      waveLength,
      pool.minimumBurnRateWeiPerBlock
    );
    const maxBurnRatePerPulse = calcResidentBurnPerPulse(
      waveLength,
      pool.maximumBurnRateWeiPerBlock
    );
    const poolStartsIn = pulse.pulseStartBlock - currentBlock;
    const poolName = `${bytesToString(poolMetadata.name)}${poolStats.paused ? " (Paused)" : ""}${
      poolStartsIn > 0 ? " (Starts in " + poolStartsIn + " blocks)" : ""
    }`;

    const poolInstance = getPoolInstance("resident");

    const days = 7;
    const { avgBlockTime } = getChainSettings(poolInstance.chainId);
    const blocksPerDay = Math.floor(secondsPerDay / avgBlockTime);
    const blocksPerWeek = blocksPerDay * days;
    const pulseLengthByWeeks = pulse.pulseWavelengthBlocks / blocksPerWeek;
    const totalLiquidity = await calcResidentTotalLiqudity(pool);

    const [depositTokenSymbol, reward1TokenSymbol] = await Promise.all([
      getTokenSymbol(pool.liquidityToken),
      getTokenSymbol(pulse.rewardToken1)
    ]);

    let reward2TokenSymbol = reward1TokenSymbol;

    if (pulse.rewardToken1 != pulse.rewardToken2) {
      reward2TokenSymbol = await getTokenSymbol(pulse.rewardToken2);
    }

    const symbols = {
      depositTokenSymbol: depositTokenSymbol,
      reward1TokenSymbol: reward1TokenSymbol,
      reward2TokenSymbol: reward2TokenSymbol
    };

    return {
      maxStakers: pool.maxStakers,
      minDeposit: fromWei(pool.minimumDepositWei),
      maxDeposit: fromWei(pool.maximumDepositWei),
      minBurnRate: pool.minimumBurnRateWeiPerBlock,
      minBurnRatePerPulse: fromWei(minBurnRatePerPulse),
      maxBurnRatePerPulse: fromWei(maxBurnRatePerPulse),
      pulseStartBlock: pulse.pulseStartBlock,
      pulseWavelength: pulse.pulseWavelengthBlocks,
      pulseAmplitudeWei: pulse.pulseAmplitudeWei,
      pulseLengthByWeeks: pulseLengthByWeeks,
      currentReward: parseFloat(currentReward),
      rewardsPerPulse: parseFloat(fromWei(rewardsPerPulse.toString())),
      totalStaked: fromWei(poolStats.totalStakedWei),
      totalRewards: parseFloat(fromWei(poolStats.totalRewardsWei)),
      totalLiquidity: totalLiquidity,
      name: poolName,
      liquidityToken: pool.liquidityToken,
      paused: poolStats.paused,
      pulseIntegral: fromWei(pulse.pulseIntegral),
      symbols: symbols
    };
  } catch (error) {
    console.log("Error while getting resident pool", error);
  }
}

export async function getResidentGlobalsAll(light = false) {
  try {
    const poolInstance = getPoolInstance("resident");
    const { chainId, contract: contractAbi } = poolInstance;

    if (!contractAbi.networks[chainId] || !contractAbi.networks[chainId].address) return [];

    const contract = await ethInstance.getContract("read", poolInstance.contract, chainId);
    const poolIndexMax = await contract.numPools();

    const items = [];
    const currentBlock = await getCurrentBlockNumber();
    for (let index = 1; index <= poolIndexMax; index++) {
      if (specialPools.ignoredResident[chainId]?.includes(index)) continue;

      const multi = await getResidentGlobalsMulti(index);
      const pulse = multi[0];
      const pool = multi[1];
      const poolStats = multi[2];
      const poolMetadata = multi[3];

      const poolStartsIn = pulse.pulseStartBlock - currentBlock;
      const poolName = `${bytesToString(poolMetadata.name)}${poolStats.paused ? " (Paused)" : ""}${
        poolStartsIn > 0 ? " (Starts in " + poolStartsIn + " blocks)" : ""
      }`;

      if (light) {
        items.push({
          index: index,
          liquidityToken: pool.liquidityToken,
          name: poolName,
          paused: poolStats.paused
        });
      } else {
        const currentReward = calcResidentRewardForBlock(pulse, toBN(currentBlock));
        const rewardsPerPulse = calcResidentRewardPerPulse(pulse);
        const totalLiquidity = await calcResidentTotalLiqudity(pool);

        items.push({
          index: index,
          maxStakers: pool.maxStakers,
          minDeposit: fromWei(pool.minimumDepositWei),
          maxDeposit: fromWei(pool.maximumDepositWei),
          minBurnRate: pool.minimumBurnRateWeiPerBlock,
          pulseStartBlock: pulse.pulseStartBlock,
          pulseWavelength: pulse.pulseWavelengthBlocks,
          currentReward: parseFloat(currentReward),
          rewardsPerPulse: parseFloat(fromWei(rewardsPerPulse.toString())),
          totalStaked: fromWei(poolStats.totalStakedWei),
          totalRewards: parseFloat(fromWei(poolStats.totalRewardsWei)),
          totalLiquidity: totalLiquidity,
          liquidityToken: pool.liquidityToken,
          name: poolName,
          paused: poolStats.paused
        });
      }
    }

    return items;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function getResidentSlot(poolIndex, slotId) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const slot = await contract.getSlot(poolIndex, slotId);
  let burn;
  let deposit;
  let rewards;

  /*
  0 - lastUpdatedBlock
  1 - depositWei
  2 - burnRateWei
  3 - rewardsWeiForSession
  4 - owner
  */
  if (slot[5] === ZERO_ADDRESS) {
    burn = deposit = "0";
    rewards = { rewards1: "0", rewards2: "0" };
  } else {
    try {
      burn = await contract.getBurn(poolIndex, slotId);
    } catch (error) {
      console.error(`slot ${slotId} error: `, error);
      burn = 0;
    }

    rewards = await getResidentRewards(poolIndex, slotId);
    deposit = fromWei((slot[1] - burn).toString());
  }

  const burnPerPulse = fromWei(await getResidentBurnPerPulse(poolIndex, slot[2]));
  const rewardsForSession = fromWei(slot[3]);
  const rewardsSum = parseFloat(rewardsForSession ?? 0) + parseFloat(rewards?.rewards1 ?? 0);
  const rewardsPerBlock = await getResidentCurrentReward(poolIndex);
  const { avgBlockTime } = getChainSettings(poolInstance.chainId);
  const rewardsPerSecond = rewardsPerBlock / avgBlockTime;
  const tokenAddress = await getResidentRewardTokenAddress(poolIndex);
  const rewardTokenPerLPToken = fromWei(
    await getResidentRewardTokenPerLPToken(poolIndex, tokenAddress)
  );

  let apy = "0";

  if (parseFloat(rewardTokenPerLPToken) === 0 || parseFloat(deposit) === 0) {
    apy = "∞";
  } else {
    apy = ((rewardsPerSecond * secondsPerYear) / (rewardTokenPerLPToken * deposit)) * 100;
  }

  return {
    index: slotId,
    owner: slot[5],
    burnRate: fromWei(slot[2]),
    burnRatePerPulse: burnPerPulse,
    rewards1: rewards?.rewards1,
    rewards2: rewards?.rewards2,
    rewardsForSession: rewardsForSession,
    rewardsSum: rewardsSum,
    apy: apy.toString(),
    apepy: await getResidentAPEPYFromBurnRate(poolIndex, burnPerPulse),
    deposit: deposit,
    initialDeposit: fromWei(slot[1])
  };
}

export async function getResidentSymbols(poolIndex) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const [pool, pulse] = await Promise.all([contract.pools(poolIndex), contract.pulses(poolIndex)]);

  const [depositTokenSymbol, reward1TokenSymbol] = await Promise.all([
    getTokenSymbol(pool.liquidityToken),
    getTokenSymbol(pulse.rewardToken1)
  ]);

  let reward2TokenSymbol = reward1TokenSymbol;

  if (pulse.rewardToken1 != pulse.rewardToken2) {
    reward2TokenSymbol = await getTokenSymbol(pulse.rewardToken2);
  }

  return {
    depositTokenSymbol: depositTokenSymbol,
    reward1TokenSymbol: reward1TokenSymbol,
    reward2TokenSymbol: reward2TokenSymbol
  };
}

export function calcResidentRewardForBlock(pulse, blockNum) {
  const waveLength = toBN(pulse.pulseWavelengthBlocks);
  const amplitude = toBN(pulse.pulseAmplitudeWei);
  const startBlock = toBN(pulse.pulseStartBlock);
  const pulseStartBlock = waveLength.mul(blockNum.sub(startBlock).div(waveLength)).add(startBlock);
  const pulseIndex = blockNum.sub(pulseStartBlock);
  // pulse.pulseAmplitudeWei.times(PRECISION) / pulse.pulseWavelengthBlocks.times(pulse.pulseWavelengthBlocks)
  const pulseConstant = amplitude.mul(PRECISION).div(waveLength.mul(waveLength));
  const currentReward = pulseConstant
    .mul(waveLength.sub(pulseIndex).mul(waveLength.sub(pulseIndex)))
    .div(PRECISION);

  return fromWei(currentReward.toString());
}

export async function getResidentRewardForBlock(poolIndex, blockNum) {
  const poolInstance = getPoolInstance("resident");

  const [contract, wavelength] = await Promise.all([
    ethInstance.getContract("read", poolInstance.contract),
    getResidentWaveLength(poolIndex, poolInstance.chainId)
  ]);

  const pulse = await contract.pulses(poolIndex);
  const amplitude = toBN(pulse.pulseAmplitudeWei);
  const startBlock = toBN(pulse.pulseStartBlock);

  const pulseStartBlock = wavelength.mul(blockNum.sub(startBlock).div(wavelength)).add(startBlock);
  const pulseIndex = blockNum.sub(pulseStartBlock);
  const pulseConstant = amplitude.mul(PRECISION).div(wavelength.sqr());
  const currentReward = pulseConstant.mul(wavelength.sub(pulseIndex).sqr()).div(PRECISION);

  return fromWei(currentReward);
}

export async function getResidentDistanceToPulseEnd(poolIndex) {
  const poolInstance = getPoolInstance("resident");

  const [contract, blockNum] = await Promise.all([
    ethInstance.getContract("read", poolInstance.contract),
    getCurrentBlockNumber()
  ]);

  const pulse = await contract.pulses(poolIndex);
  const pulsesPassed = Math.floor((blockNum - pulse.pulseStartBlock) / pulse.pulseWavelengthBlocks);
  const pulseEndBlock =
    pulse.pulseWavelengthBlocks * (pulsesPassed + 1) + parseInt(pulse.pulseStartBlock);

  return pulseEndBlock - blockNum;
}

export async function getResidentCurrentReward(poolIndex) {
  const currentBlock = toBN(await getCurrentBlockNumber());
  return await getResidentRewardForBlock(poolIndex, currentBlock);
}

export async function getResidentTotalBurned(poolIndex) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const poolStats = await contract.poolStats(poolIndex);

  return poolStats.totalBurnedWei.toString();
}

export async function getResidentLPBalance(tokenAddress, target) {
  const lpContract = await getDepositToken("resident", null, tokenAddress);

  if (!target) {
    target = await ethInstance.getEthAccount(false);
  }

  const balance = fromWei(await lpContract.balanceOf(target));
  return balance;
}

export async function calcResidentTotalLiqudity(pool) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const lpBalance = await getResidentLPBalance(pool.liquidityToken, contract.address);

  const totalLiquidity = Math.floor(parseInt(lpBalance));
  return totalLiquidity;
}

export async function getResidentTotalLiquidity(poolIndex) {
  const poolInstance = getPoolInstance("resident");

  const yieldContract = await ethInstance.getContract("read", poolInstance.contract);

  const [pool, poolStats] = await Promise.all([
    yieldContract.pools(poolIndex),
    yieldContract.poolStats(poolIndex)
  ]);

  const lpBalance = await getResidentLPBalance(pool.liquidityToken, yieldContract.address);
  const burnAmount = fromWei(poolStats.totalBurnedWei);
  const totalLiquidity = Math.floor(parseInt(lpBalance) + parseInt(burnAmount));

  return totalLiquidity;
}

export async function getResidentRewardGraph(datas, samplingWavelength = 1) {
  const poolInstance = getPoolInstance("resident");

  const { waveLength, pulseAmplitudeWei, pulseStartBlock } = datas;
  const currentBlock = await ethInstance.getBlock("latest");
  const waveLengthBN = toBN(waveLength);
  const amplitude = toBN(pulseAmplitudeWei);
  const currentBlockNumber = toBN(currentBlock.number);
  const startBlock = toBN(pulseStartBlock);
  const { avgBlockTime } = getChainSettings(poolInstance.chainId);
  const avgBlockTimeSeconds = toBN(Math.round(avgBlockTime));
  const calcPulseStartBlock = waveLengthBN
    .mul(currentBlockNumber.sub(startBlock).div(waveLengthBN))
    .add(startBlock);

  let pulseStartBlockTime = toBN(0);

  if (calcPulseStartBlock.gt(currentBlockNumber)) {
    pulseStartBlockTime = toBN(currentBlock.timestamp).add(
      calcPulseStartBlock.sub(currentBlockNumber).mul(avgBlockTimeSeconds)
    );
  } else {
    const block = await ethInstance.getBlock(parseInt(calcPulseStartBlock.toString()));
    pulseStartBlockTime = toBN(block.timestamp);
  }

  const pulseConstant = amplitude.mul(PRECISION).div(waveLengthBN.mul(waveLengthBN));
  const timeSeries = [];

  for (let j = 0; j < waveLengthBN.toNumber(); j += samplingWavelength) {
    const i = toBN(j.toString());

    timeSeries.push({
      x: new Date(pulseStartBlockTime.add(i.mul(avgBlockTimeSeconds)).toString() * 1000),
      y: parseFloat(
        fromWei(pulseConstant.mul(waveLengthBN.sub(i).mul(waveLengthBN.sub(i))).div(PRECISION))
      )
    });
  }

  return timeSeries;
}

export async function getResidentCurrentBlockXY(datas) {
  const { waveLength, pulseAmplitudeWei, pulseStartBlock } = datas;

  const currentBlock = await ethInstance.getBlock("latest");
  const currentBlockNumber = currentBlock.number;
  const currentTimestamp = currentBlock.timestamp;
  const startBlock = toBN(pulseStartBlock);
  const pulsesPassed = Math.floor((currentBlockNumber - startBlock) / waveLength);
  const pulseEndBlock = waveLength * (pulsesPassed + 1) + parseInt(startBlock);
  const amplitude = toBN(pulseAmplitudeWei);
  const waveLengthBN = toBN(waveLength);
  const pulseConstant = amplitude.mul(PRECISION).div(waveLengthBN.mul(waveLengthBN));
  const i = toBN((pulseEndBlock - currentBlockNumber).toString());

  return {
    x: new Date(currentTimestamp.toString() * 1000),
    y: parseFloat(fromWei(pulseConstant.mul(i * i).div(PRECISION)))
  };
}

export async function getResidentAmplitude(poolIndex) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const pulse = await contract.pulses(poolIndex);
  const amplitude = pulse.pulseAmplitudeWei;

  return fromWei(amplitude);
}

export function calcResidentRewardPerPulse(pulse) {
  const waveLength = toBN(pulse.pulseWavelengthBlocks);
  const amplitude = toBN(pulse.pulseAmplitudeWei);
  const pulseConstant = amplitude.mul(PRECISION).div(waveLength.mul(waveLength));
  const pulseRewards = pulseConstant
    .mul(waveLength.mul(waveLength.add(1)))
    .mul(waveLength.mul(2).add(1))
    .div(PRECISION.mul(6));

  return pulseRewards;
}

export async function getResidentRewardsPerPulse(poolIndex) {
  const poolInstance = getPoolInstance("resident");

  const [contract, wavelength] = await Promise.all([
    ethInstance.getContract("read", poolInstance.contract),
    getResidentWaveLength(poolIndex, poolInstance.chainId)
  ]);

  const pulse = await contract.pulses(poolIndex);
  const amplitude = toBN(pulse.pulseAmplitudeWei);
  const pulseConstant = amplitude.mul(PRECISION).div(wavelength.mul(wavelength));
  const pulseRewards = pulseConstant
    .mul(wavelength.mul(wavelength.add(1)))
    .mul(wavelength.mul(2).add(1))
    .div(PRECISION.mul(6));

  return pulseRewards;
}

export async function getResidentRewardTokenAddress(poolIndex) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const pulse = await contract.pulses(poolIndex);
  const rewardTokenAddress = pulse.rewardToken1;

  return rewardTokenAddress;
}

export function calcResidentBurnPerPulse(waveLength, burnPerBlock) {
  const waveLengthBN = toBN(waveLength);
  const burnBN = toBN(burnPerBlock);
  return waveLengthBN.mul(burnBN);
}

export async function getResidentBurnPerPulse(poolIndex, burnPerBlock) {
  const poolInstance = getPoolInstance("resident");
  const wavelength = await getResidentWaveLength(poolIndex, poolInstance.chainId);
  const burnBN = toBN(burnPerBlock);

  return wavelength.mul(burnBN);
}

export async function getResidentBurnPerBlock(poolIndex, burnPerPulse) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const pulses = await contract.pulses(poolIndex);
  const burnBN = toBN(burnPerPulse);

  return burnBN.div(pulses.pulseWavelengthBlocks);
}

export async function getResidentSlots(poolIndex, currentNetwork) {
  const poolInstance = getPoolInstance("resident");

  const maxStakers = parseInt(await getMaxResidentStakers(poolIndex, currentNetwork));

  let slots = [];
  for (let index = 1; index <= maxStakers; index++) {
    slots.push([poolIndex, index]);
  }

  const [slotsResult, rewardsResult, burnsResult, pulse] = await getResidentSlotsMulti(
    poolIndex,
    slots
  );

  // calc rewards per block
  const blockNum = toBN(await getCurrentBlockNumber());
  const waveLength = toBN(pulse[0].pulseWavelengthBlocks);
  const tokenAddress = pulse[0].rewardToken1;
  const rewardsPerBlock = calcResidentRewardForBlock(pulse[0], blockNum);

  const rewardTokenPerLPToken = fromWei(
    await getResidentRewardTokenPerLPToken(poolIndex, tokenAddress)
  );

  const results = slotsResult.map((slot, index) => {
    let burn;
    let deposit;
    let rewards;

    if (slot[5] === ZERO_ADDRESS) {
      burn = deposit = "0";
      rewards = ["0", "0"];
    } else {
      try {
        burn = burnsResult[index][0];
      } catch (error) {
        console.error(`slot ${index + 1} error: `, error);
        burn = 0;
      }

      rewards = rewardsResult[index];
      deposit = (fromWei(slot[1]) - fromWei(burn)).toString();
    }

    const burnPerPulse = fromWei(waveLength.mul(toBN(slot[2])));
    const rewardsForSession = fromWei(slot[3]);
    const rewardsSum = parseFloat(rewardsForSession ?? 0) + parseFloat(fromWei(rewards?.[0] ?? 0));
    const { avgBlockTime } = getChainSettings(poolInstance.chainId);
    const rewardsPerSecond = rewardsPerBlock / avgBlockTime;

    //calc apy
    let apy = "0";
    if (parseFloat(rewardTokenPerLPToken) === 0 || parseFloat(deposit) === 0) {
      apy = "∞";
    } else {
      apy = ((rewardsPerSecond * secondsPerYear) / (rewardTokenPerLPToken * deposit)) * 100;
    }

    //calc apepy
    const fvtPerLP = toWei(rewardTokenPerLPToken);
    const pulseRewards = calcResidentRewardPerPulse(pulse[0]);
    const apepy = calcResidentAPEPYFromBurnRate(fvtPerLP, burnPerPulse, pulseRewards);

    const rs = {
      index: index + 1,
      owner: slot[5],
      burnRate: fromWei(slot[2]),
      burnRatePerPulse: burnPerPulse,
      rewards1: fromWei(rewards?.[0]),
      rewards2: fromWei(rewards?.[1]),
      rewardsForSession: rewardsForSession,
      rewardsSum: rewardsSum,
      apy: apy.toString(),
      apepy: apepy,
      deposit: deposit,
      initialDeposit: fromWei(slot[1]),
      lastUpdatedBlock: slot[0]
    };

    return rs;
  });

  const list = { all: [], occupied: [] };

  results.forEach((x) => {
    if (x.owner !== ZERO_ADDRESS) {
      x.vacant = false;
      list.occupied.push(x);
    } else {
      x.vacant = true;
    }

    list.all.push(x);
  });
  return list;
}

export async function getResidentAverageStats(slotIds) {
  let avgAPEPY = "∞";
  let avgAPY = "∞";
  if (slotIds && slotIds.occupied) {
    const { apepys = [], apys = [] } = slotIds.occupied.reduce(
      (prev, curr) => {
        let apepys;
        let apys;
        if (curr.apepy) {
          apepys = [...prev.apepys, curr.apepy];
        }
        if (curr.apy) {
          apys = [...prev.apys, curr.apy];
        }
        return { apepys, apys };
      },
      { apepys: [], apys: [] }
    );
    if (apepys?.length && !apepys.some((x) => x === "∞")) {
      avgAPEPY = apepys.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / apepys.length;
    }
    if (apys?.length && !apys.some((x) => x === "∞")) {
      apys.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / apys.length;
    }
  }
  return { avgAPEPY, avgAPY };
}

export async function getResidentAverageAPEPY(slotIds) {
  if (slotIds && slotIds.occupied) {
    const apepys = slotIds.occupied.map((x) => x.apepy);

    if (apepys.some((x) => x === "∞")) {
      return "∞";
    }

    return apepys.length === 0
      ? "∞"
      : apepys.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / apepys.length;
  } else {
    return "∞";
  }
}

export async function getResidentAverageAPY(slotIds) {
  if (slotIds && slotIds.occupied) {
    const apys = slotIds.occupied.map((x) => x.apy);

    if (apys.some((x) => x === "∞")) {
      return "∞";
    }

    return apys.length === 0
      ? "∞"
      : apys.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / apys.length;
  } else {
    return "∞";
  }
}

export async function getResidentRewardTokenPerLPToken(poolIndex, rewardTokenAddress) {
  const poolInstance = getPoolInstance("resident");

  const resContract = await ethInstance.getContract("read", poolInstance.contract);
  const pool = await resContract.pools(poolIndex);

  const contract = await ethInstance.getReadContractByAddress(
    contracts.LPToken,
    pool.liquidityToken
  );

  const [token0, token1] = await Promise.all([contract.token0(), contract.token1()]);

  let reserveIndex = 0;

  if (token1.toLowerCase() === rewardTokenAddress.toLowerCase()) {
    reserveIndex = 1;
  } else if (token0.toLowerCase() !== rewardTokenAddress.toLowerCase()) {
    return toBN(0);
  }

  const [reserves, totalSupply] = await Promise.all([
    contract.getReserves(),
    contract.totalSupply()
  ]);

  return toBN(reserves[reserveIndex])
    .mul(toBN(toWei("2")))
    .div(toBN(totalSupply));
}

export function calcResidentAPEPYFromBurnRate(fvtPerLP, lpBurnPerPulse, rewardsPerPulse) {
  let fvtBurnPerPulse = toBN(fvtPerLP.toString())
    .mul(toBN(toWei(lpBurnPerPulse.toString())))
    .div(toBN((1e18).toString())); // this division is only to remove the additional multiplication done by parsing lpBurnPerPulse to WEI
  fvtBurnPerPulse = fromWei(fvtBurnPerPulse.toString()); //revert WEI from base fvtPerLP
  fvtBurnPerPulse = toBN(fvtBurnPerPulse.split(".")[0]); // similar to math.floor needed to be able to convert the number to BN
  if (fvtBurnPerPulse.eq(0)) {
    if (rewardsPerPulse.eq(0)) {
      return "0";
    } else {
      return "∞";
    }
  } else {
    const apepy = fromWei(rewardsPerPulse.mul(toBN("100")).div(fvtBurnPerPulse).toString());
    return apepy;
  }
}

export async function getResidentAPEPYFromBurnRate(poolIndex, lpBurnPerPulse) {
  const [rewardTokenAddress, rewardsPerPulse] = await Promise.all([
    getResidentRewardTokenAddress(poolIndex),
    getResidentRewardsPerPulse(poolIndex)
  ]);

  const fvtPerLP = await getResidentRewardTokenPerLPToken(poolIndex, rewardTokenAddress);
  const result = calcResidentAPEPYFromBurnRate(fvtPerLP, lpBurnPerPulse, rewardsPerPulse);
  return result;
}

export async function getResidentClaimMinimums(poolIndex, slotIndex) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const result = await contract.getClaimMinimums(poolIndex, slotIndex);

  return { minDeposit: result[0], minBurnRate: result[1] };
}

export async function getResidentAPEPYFromSlot(poolIndex, slotIndex) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const [wavelength, slot] = await Promise.all([
    getResidentWaveLength(poolIndex, poolInstance.chainId),
    contract.contract.getSlot(poolIndex, slotIndex) //0 - lastUpdatedBlock, 1 - depositWei, 2 - burnRateWei, 3 - rewardsForSession, 4 - owner
  ]);

  return getResidentAPEPYFromBurnRate(poolIndex, fromWei(wavelength.mul(toBN(slot[2])).toString()));
}

export async function getResidentRewardsForSession(poolIndex, slotId) {
  const poolInstance = getPoolInstance("resident");

  const contract = await ethInstance.getContract("read", poolInstance.contract);
  const [rewards, notYetClaimed] = await Promise.all([
    contract.rewardsForSession(poolIndex, slotId),
    getResidentRewards(poolIndex, slotId)
  ]);

  const rewardForSession = fromWei(rewards);

  return rewardForSession + notYetClaimed.rewards1;
}

export async function getResident24hrsVolume(poolInstance, poolIndex) {
  const { avgBlockTime } = getChainSettings(poolInstance.chainId);
  const blocksPerDay = secondsPerDay / avgBlockTime;

  const currentBlock = (await ethInstance.getBlock("latest")).number;
  const fromBlock = Math.floor(currentBlock - blocksPerDay);

  const filters = {
    fromBlock,
    args: {
      poolId: poolIndex
    }
  };

  try {
    const allEvents = await getEventsFromCache(poolInstance, "SlotChangedHands", filters);
    const parsedData = allEvents.map((x) => {
      return parseBNData(x.data, RESULT_TYPES.OBJECT, true);
    });

    const volume = parsedData
      .map((x) => parseFloat(fromWei(x.depositWei)))
      .reduce((a, b) => a + b, 0);

    return volume;
  } catch (error) {
    console.error(error);
  }
}

export async function getResidentAccountAllowance(tokenAddress, contractType) {
  let currentApproval = "0";
  if (tokenAddress) {
    const { account, isConnected } = await accountConnected();
    if (isConnected) {
      const [liquidity, token] = await Promise.all([
        getWriteContractByType(contractType),
        ethInstance.getWriteContractByAddress(contracts.LPToken, tokenAddress)
      ]);

      try {
        currentApproval = await token.allowance(account, liquidity.address);
      } catch (error) {
        //changing chain error
        console.log("getResidentAllowace fail: ", error);
      }
    }
  }

  const result = parseFloat(fromWei(currentApproval));
  return result;
}

export async function getUserStats(poolIndex) {
  let userStats = {
    0: "0",
    1: "0",
    2: "0"
  };

  const { account, isConnected } = await accountConnected();

  if (isConnected) {
    const poolInstance = getPoolInstance("resident");
    const contract = await ethInstance.getContract("read", poolInstance.contract);
    userStats = await contract.getUserStats(poolIndex, account);
  }

  return {
    totalStaked: fromWei(userStats[0]),
    totalRewards: fromWei(userStats[1]),
    totalBurned: fromWei(userStats[2])
  };
}

export async function addResidentPool(pool) {
  const liquidity = await getWriteContractByType("resident");
  let newPoolTransaction = null;
  let configTransaction = null;

  try {
    const newPool = await liquidity.addPool(
      pool.rewardToken1Addr,
      pool.rewardToken2Addr,
      pool.liquidityTokenAddr,
      pool.taxAddr,
      pool.poolOwner,
      pool.rewardPerBlock2Wei,
      pool.pulseStartDelayBlocks,
      getBytes(pool.ipfsHash),
      stringToBytes(pool.name)
    );

    newPoolTransaction = await newPool.wait();

    if (newPoolTransaction.status === TRANSACTION_STATUS.CONFIRMED) {
      const poolIndex = newPoolTransaction.events
        ?.find((x) => x.event === "PoolAdded")
        .args?.poolId.toString();

      const config = await liquidity.setConfig(
        poolIndex,
        pool.mxStkrs,
        pool.minDepositWei,
        pool.maxDepositWei,
        pool.minBurnRateWei,
        pool.maxBurnRateWei,
        pool.pulseLengthBlocks,
        pool.pulseAmplitudeWei
      );

      configTransaction = await config.wait();
    }
  } catch (error) {
    console.error("addResidentPool: ", error);
  }

  return (
    newPoolTransaction.status === TRANSACTION_STATUS.CONFIRMED &&
    configTransaction.status === TRANSACTION_STATUS.CONFIRMED
  );
}

export async function unpauseResidentPool(poolIndex) {
  const liquidity = await getWriteContractByType("resident");
  const res = await liquidity.unpausePool(poolIndex);
  return await res.wait();
}

export async function getResidentSlotsMulti(poolIndex, slots) {
  try {
    const poolInstance = getPoolInstance("resident");
    const jsonResident = poolInstance.contract;
    const chainId = poolInstance.chainId;
    const address = jsonResident.networks[chainId].address;

    const methods = ["getSlot", "getRewards", "getBurn", "pulses"];
    const slotMethods = ["getSlot", "getRewards", "getBurn"];

    const calls = slotMethods.map((name) => {
      return { name: name, params: slots, resultType: RESULT_TYPES.ARRAY };
    });

    calls.push({
      name: "pulses",
      params: [[poolIndex]],
      resultType: RESULT_TYPES.OBJECT
    });

    if (calls.length) {
      const contract = await ethInstance.getContract("read", contracts.Multicall);
      const interf = getInterface(poolInstance.contract.abi);

      const groups = calls.reduce((acc, value) => {
        if (!acc[value.name]) acc[value.name] = { name: "", calls: [] };

        acc[value.name].name = value.name;
        acc[value.name].calls = value.params.map((params) => [
          address,
          interf.encodeFunctionData(value.name, params)
        ]);

        return acc;
      }, {});

      const response = await Promise.all(
        Object.values(groups).map((x) => contract.aggregate(x.calls))
      );

      const decodedResponse = response.map((group, index) => {
        return group.returnData.map((x) => {
          return interf.decodeFunctionResult(methods[index], x);
        });
      });

      return decodedResponse.map((x, index) => {
        return parseBNData(x, calls[index].resultType);
      });
    }
    return [];
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
}

export async function getResidentGlobalsMulti(poolIndex) {
  try {
    const poolInstance = getPoolInstance("resident");
    const jsonResident = poolInstance.contract;
    const chainId = poolInstance.chainId;

    const methods = ["pulses", "pools", "poolStats", "metadatas"];
    const address = jsonResident.networks[chainId] && jsonResident.networks[chainId].address;

    if (!address) return [];

    const requests = methods.map((name) => {
      return {
        name: name,
        params: [poolIndex]
      };
    });

    if (requests.length) {
      const contract = await ethInstance.getReadContractByAddress(
        contracts.Multicall,
        contracts.Multicall.networks[chainId].address
      );

      const interf = getInterface(poolInstance.contract.abi);

      const calls = requests.map((call) => {
        return [address, interf.encodeFunctionData(call.name, call.params)];
      });

      const response = await contract.aggregate(calls, {});

      const decodedResponse = response.returnData.map((resp, index) => {
        return interf.decodeFunctionResult(methods[index], resp);
      });

      const resp = decodedResponse.map((x) => {
        return parseBNData(x, RESULT_TYPES.OBJECT, true);
      });

      return resp;
    }
  } catch (e) {
    console.log(e);
    return [];
  }
}
